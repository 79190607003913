import { useState, useEffect, useRef } from 'react';
import { UserContext, AppContext } from './global/globalContext/context';
import { RouterProvider } from "react-router-dom";
import { router } from './global/utils/router';
import AuthContext from './global/globalContext/context';
import { PrimeReactProvider } from 'primereact/api';
import { getRequest } from './global/utils/apiCall';
import Favicon from 'react-favicon';
import { HelmetProvider } from 'react-helmet-async';
import SEO from './global/Components/SEO/SEO';
import { Toast } from 'primereact/toast';

const MENU_URL = 'api/v1/categories/mega-menu';
const FOOTER_URL = 'api/v1/footers';
const SETTING_URL = 'api/v1/setting';

function App() {
  const helmetContext = {};
  const toast = useRef(null);

  const [user, setUser] = useState("");
  const [lang, setLang] = useState();
  const [auth, setAuth] = useState(JSON.parse(localStorage.getItem('auth')));
  const [persist, setPersist] = useState(JSON.parse(localStorage.getItem('persist')) || false);
  const [menuAll, setMenuAll] = useState([]);
  const [footer, setFooter] = useState();
  const [setting, setSetting] = useState();

  const showSuccess = (message) => {
    toast.current.show({severity:'success', summary: 'Success', detail: message, life: 3000});
  }
  const showError = (message) => {
      toast.current.show({severity:'error', summary: 'Error', detail: message, life: 3000});
  }

  const callFooter = async () => {
    const response = await getRequest(FOOTER_URL);
    setFooter(response.data.data);
  }

  const callMenu = async () => {
    
    try {
        const response = await getRequest(MENU_URL);
        setMenuAll(response.data.data);
    }
    catch (error) {
      if (error?.response?.status === 400 || error?.response?.status === 401 || error?.response?.status === 422 || error?.response?.status === 429 || error?.response?.status === 500) {
        showError(error?.response?.data?.message)
      } 
    }
  }

  const  callSetting = async () => {
    
    try{
      const response = await getRequest(SETTING_URL);
      setSetting(response.data.data);
    }
    catch (error) {
      if (error?.response?.status === 400 || error?.response?.status === 401 || error?.response?.status === 422 || error?.response?.status === 429 || error?.response?.status === 500) {
        showError(error?.response?.data?.message)
      } 
    }
  }

  useEffect(() => {
    callSetting();
    callMenu();
    callFooter();
  },[]);

  return (
    <>
    <Toast ref={toast} position="top-center" />
    <HelmetProvider context={helmetContext}>
      <AuthContext.Provider value={{auth, setAuth, persist, setPersist}}>
        <UserContext.Provider value={{user, setUser, lang, setLang}}>
          <AppContext.Provider value={{menuAll, setMenuAll, footer, setFooter, setting}}>
            <PrimeReactProvider>
              <SEO description={setting && setting.description} />
              {
                setting &&
                  <Favicon url={`${`${process.env.REACT_APP_API_URL}${setting.favicon}`}` }/>
              }
              <RouterProvider router={router} />
            </PrimeReactProvider>
          </AppContext.Provider>
        </UserContext.Provider>
      </AuthContext.Provider>
    </HelmetProvider>
    </>
  );
}

export default App;
