import { Navigate, Outlet, useNavigate } from "react-router-dom";
import useAuth from "../../global/utils/hooks/useAuth";
import usePathname from "../../global/utils/hooks/usePathname";
import useApiPrivate from "../../global/utils/hooks/useApiPrivate";
import { useEffect, useLayoutEffect } from "react";

const USER_URL = 'api/v1/user';

const RequireAuth = ({allowRoles}) => {
    const { auth, setAuth } = useAuth();
    const currentLocation = usePathname();
    const ApiPrivate = useApiPrivate();
    const navigate = useNavigate();

    const isUserLogin = async () => {
        try {
            const response = await ApiPrivate.get(USER_URL);
            const localUser = JSON.parse(localStorage.getItem('auth'));
            const userInfo = response?.data.data;
            localUser.user =  userInfo.username;
            localUser.roles =  userInfo.is_seller;
            setAuth(localUser);
            localStorage.setItem("auth",JSON.stringify(localUser));
        }
        catch (error) {
            setAuth('');
            navigate('/login', {replace: true});
        }
    }

    useEffect(() => {
        isUserLogin();
    },[]);

    return (
        [auth?.roles]?.find(role => allowRoles?.includes(role))
            ? <Outlet />
            : <Navigate to='/unauthorized' state={{from: currentLocation}} replace />
            // : (auth?.token)
                // : <Navigate to='/login' state={{from: currentLocation}} replace />            
    )
}

export default RequireAuth;