import styles from './ComingSoon.module.scss';
import SEO from '../../global/Components/SEO/SEO';
import { useContext } from 'react';
import { AppContext } from '../../global/globalContext/context';
import { Col, Row, Container } from 'reactstrap';
import { Helmet } from 'react-helmet-async';

export default function ComingSoon() {
    const { setting } = useContext(AppContext);
  return (
    <>
        <SEO title={'ComingSoon'} />
        <Helmet>
        <style>{'body { background-color: #f15d22; }'}</style>
        </Helmet>
        <Container className='bg-primary'>
            <Row>
                <Col sm={12} md={{size:6, offset:3}} >
                <div className='text-center'>
                    <img src={`/coming_soon.png`}  className='img-fluid'/>
                </div>
                </Col>
            </Row>
        </Container>
    </>
  )
}
