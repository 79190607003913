import React, { useContext } from 'react'
import { Helmet } from 'react-helmet-async'
import { AppContext } from '../../globalContext/context'

export default function SEO({ title, description, tags}) {
    const { setting } = useContext(AppContext);

  return (
    <Helmet>
         <title>
            {
                title ? `${setting ? `${setting.title} | ` : '' }${title}` : setting && setting.title ? `${setting.title}` : 'Letgo OR Trade'
            }
        </title>
        <meta name='description' content={description ? `${description}` : setting && setting.description ? `${setting.description}` : 'Letgo OR Trade'} />
        <meta name='keywords' content={tags ? `${tags}` : setting && setting.keyword ? `${setting.keyword}` : 'letgo, sell, product, listing, marketplace' } />
    </Helmet>
  )
}
