import { createBrowserRouter  } from "react-router-dom";
import CategoryPage from "../../pages/Category/CategoryPage";
import HomePage from "../../pages/HomePage/HomePage";
import ProductPage from "../../pages/ProductPage/ProductPage";
import VendorPage from "../../pages/VendorPage/VendorPage";
import RegisterPage from "../../pages/RegisterPage/RegisterPage";
import Dashboard from "../../pages/Dashboard/Dashboard";
import RequireAuth from "../../pages/LoginPage/RequireAuth";
import NotFound from "../../pages/NotFound/NotFound";
import ForgotPasswordPage from "../../pages/LoginPage/ForgotPasswordPage/ForgotPasswordPage";
import ResetPasswordPage from "../../pages/LoginPage/ResetPasswordPage/ResetPasswordPage";
import AddProduct from "../../pages/Dashboard/AddProduct/AddProduct";
import CategoriesPage from "../../pages/CategoriesPage/CategoriesPage";
import Users from "../../pages/LoginPage/Users";
import Unauthorized from "../../pages/LoginPage/Unauthorized";
import FavoriteList from "../../pages/Dashboard/Favorite/FavoriteList";
import UserProfile from "../../pages/Dashboard/UserProfile/UserProfile";
import MyProducts from "../../pages/Dashboard/MyProducts/MyProducts";
import EditProduct from "../../pages/Dashboard/EditProduct/EditProduct";
import ConfirmedEmail from "../../pages/RegisterPage/ConfirmedEmail/ConfirmedEmail";
import MyOrders from "../../pages/Dashboard/MyOrders/MyOrders";
import AddFeedback from "../Components/Feedback/AddFeedback";
import AdvertiseList from "../../pages/Dashboard/AdvertiseList/AdvertiseList";
import ContactUs from "../../pages/ContactUs/ContactUs";
import MyTransactions from "../../pages/Dashboard/MyTransactions/MyTransactions";
import Charge from "../Components/Charge/Charge";
import AboutUs from "../../pages/AboutUs/AboutUs";
import LoginWithReCaptcha from "../Components/ReCaptcha/LoginWithReCaptcha";
import Page from "../../pages/Page/Page";
import ComingSoon from "../../pages/ComingSoon/ComingSoon";

  const ROLES = {
    'User': 'no',
    'Seller': 'yes',
  }

export const router = createBrowserRouter([
  {
    path: "/",
    element: <ComingSoon />,
  },
  // {
  //   path: "/",
  //   element: <HomePage />,
  // },
  // {
  //   path: "categories",
  //   element: <CategoriesPage />,
  // },
  // {
  //   path: "product-list/",
  //   element: <CategoryPage />,
  //   children:[
  //     {
  //       path: ":id",
  //       element: <CategoryPage />,
  //     },
  //     {
  //       path: ":id/:slug",
  //       element: <CategoryPage />,
  //     }
  //   ]
  // },
  // {
  //   path: "/contact-us",
  //   element: <ContactUs />
  // },
  // {
  //   path: "/about-us",
  //   element: <AboutUs />
  // },
  // {
  //   path: "vendor/:id",
  //   element: <VendorPage />,
  // },
  // {
  //   path: "product/",
  //   element: <ProductPage />,
  //   children:[
  //     {
  //       path: ":id",
  //       element: <ProductPage />,
  //     },
  //     {
  //       path: ":id/:slug",
  //       element: <ProductPage />,
  //     }
  //   ]
  // },
  // {
  //   path: "page/:id",
  //   element: <Page />,
  // },
  // {
  //   path: "login",
  //   element: <LoginWithReCaptcha />,
  // },
  // {
  //   path: "register",
  //   element: <RegisterPage />,
  // },
  // {
  //   path: "confirmed-email",
  //   element: <ConfirmedEmail />,
  // },
  // {
  //   path: "forgot-password",
  //   element: <ForgotPasswordPage />
  // },
  // { //must be protected
  //   path: "reset-password",
  //   element: <ResetPasswordPage />
  // },
  // {   // protected - User & Seller
  //   element: <RequireAuth allowRoles={[ROLES.Seller, ROLES.User]} />,
  //   children:[
  //     { 
  //       path: "dashboard",
  //       element: <Dashboard />,
  //       children: [
  //         {  // protected - just Seller
  //           element: <RequireAuth allowRoles={[ROLES.Seller]} />,
  //           children: [
  //             {
  //               path: "add-product",
  //               element: <AddProduct />
  //             },
  //             {
  //               path: "edit-product/:id",
  //               element: <EditProduct />
  //             },
  //             {
  //               path: "my-products",
  //               element: <MyProducts />
  //             },
  //           ]
  //         },
  //         {
  //           path: "charge",
  //           element: <Charge />
  //         },
  //         {
  //           path: "my-orders",
  //           element: <MyOrders />
  //         },
  //         {
  //           path: "wishlist",
  //           element: <FavoriteList />
  //         },
  //         {
  //           path: "user-profile",
  //           element: <UserProfile />
  //         },
  //         {
  //           path: "users",
  //           element: <Users />
  //         },
  //         {
  //           path: "my-transactions",
  //           element: <MyTransactions />
  //         },
          
  //         {
  //           path: "add-feedback",
  //           element: <AddFeedback />
  //         },
  //         {
  //           path: "advertise",
  //           element: <AdvertiseList />
  //         },
          
  //       ]
  //     },
  //   ]
  // },
  // {
  //   path: "unauthorized",
  //   element: <Unauthorized />,
  // },
  {
    path: "*",
    element: <NotFound />,
  }
  ]);