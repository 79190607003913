import { Link } from 'react-router-dom';
import styles from './NotFound.module.scss';
import SEO from '../../global/Components/SEO/SEO';

export default function NotFound() {
  return (
    <>
        <SEO title={'Page Not Found'} />
        <div className={`d-flex align-items-center justify-content-center ${styles.pageContainer}`}>
            <div className={`p-4`}>
                <div className={`fw-bolder ${styles.bigText}`}>
                    404
                </div>
                <h1>
                    Page Not Found
                </h1>
                <div>
                    <Link to='/' className={`fw-bold text-decoration-none`}>
                        Visit Our Homepage
                    </Link>
                </div>
            </div>
        </div>
    </>
  )
}
