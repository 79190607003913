

 const en = {
    rtl: false,
    name: 'English',
    msg:{
        'my_profile': 'My Profile',
        'my_products': 'My Products',
        'my_orders': 'My Orders',
        'wishlist': 'Wishlist',
        'logout': 'Logout',
        'my-transactions': 'My Transactions',
        'payment_report':' Peyment Report',
        'product_number':'Product Number',
        'report':'Report',
        'share':'Share',
        'show_all':'Show All',
        'related_products':'Related Products',
        'no_server_response':'No server response',
        'bad_request':'Bad request',
        'unauthorized':'Unauthorized',
        'login_failed':'Login failed',
        'login':'Login',
        'email_or_login':'Email or username',
        'password':'Password',
        'password_lost?':'Password lost?',
        'reset_password':'Reset password',
        'forgot-password':'Forgot password',
        'remember_me':'Remember me',
        'already_registered?':'Already registered?',
        'create_an_account':'Create an account',
        'add_product':'Listing',
        'advertise':'Advertise',
        'product-title':'Product Title',
        'send':'Send',
        'save':'Save',
        'find_anything':'Find Anything',
        'around':'Around',
        'you':'You',
        'discover_more':'Discover More',
        'featured_listings':'Featured Listings',
        'view_all':'View All',
        'featured_categories':'Featured Categories',
        'expire_soon':'Expire Soon',
        'the_cheapest_products':'The Cheapest Products',
        'recently_view_products':'Recently View Products',
        'recommended_seller':'Recommended Seller',
        'results':'Results',
        'no_results':'No Results',
        'no_products_to_display':'No Products to display',
        'close':'Close',
        'are_you_sure_you_want_to_close_product':'Are you sure you want to close product',
        'if_you_click_yes,_the_product_will_be_closed_forever':'If you click yes, the product will be closed forever',
        'yes':'Yes',
        'cancel':'Cancel',
        'edit':'Edit',
        'view':'View',
        'satisfied':'satisfied',
        'acceptable':'acceptable',
        'negative':'negative',
        'you_have':'You have',
        'positive_feedback':'positive feedback',
        '':'',
        '':'',
        '':'',

    }
}

export default en;